import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'input-html',
    templateUrl: './input-html.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputHtmlComponent), multi: true }
    ]
})

export class InputHtmlComponent extends BaseInputComponent {
	@ViewChild('editor') editor;
	@Input("maxLength") maxLength = 2048;
	@Input("requestAddPlaceholder") set  requestAddPlaceholder(field) {
		if (field) {
			if (this.editor) {
				const range = this.editor.quillEditor.getSelection(true);
				let index = range.index;
				this.editor.quillEditor.insertText(index, "${"+field+"}", "user")
			}
		}
	}

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }

    protected toInternalFormat(value: string): string {
		if (value) return value.substring(0,this.maxLength);
        return value;
    }

    protected toExternalFormat(value: string): string {
        if (value == "") {
            return null;
        }
		if (value) return value.substring(0,this.maxLength);
        
		return value;
    }

	/*
   * Delete added characters that exceeds max length
   */
	textChanged($event) {
		// console.log("$event: ", $event);
		// console.log(" $event.editor.getLength()",  $event.editor.getLength());
		// console.log(" $event.editor.getText()",  $event.editor.getText());
		
		if ($event.editor.getLength() > this.maxLength) {
		  $event.editor.deleteText(this.maxLength, $event.editor.getLength());
		}
	}
}

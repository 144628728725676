import { CommonValidators } from "app/common/components/form/validators/common.validator";


export var patternNameField = /^[a-z0-9Ññ¿¡ÜüÇçèéòàùìåäö.,&@€?!() \-]+$/i;
export var nameFieldContstraintDescription = "il campo può essere composto solo da lettere, numeri e dai simboli - . , & @ € ? ! ( ) "


export var nameFieldsValidators = [
	CommonValidators.required,
	CommonValidators.noMinusCharValidator(),
	CommonValidators.patternMatchingValidator(patternNameField,"il campo può essere composto solo da lettere, numeri e dai simboli  . , & @ € ? ! ( ) ")
]

export var cleanString = (id ) => {
	return (""+id).trim().toLowerCase()
		.replaceAll(" ", "")
		.replaceAll(".", "")
		.replaceAll(",", "")
		.replaceAll("-", "")
		.replaceAll("&", "")
		.replaceAll("è", "")
		.replaceAll("é", "")
		.replaceAll("ò", "")
		.replaceAll("à", "")
		.replaceAll("ù", "")
		.replaceAll("ì", "")
		.replaceAll("@", "")
		.replaceAll("€", "")
		.replaceAll("?", "")
		.replaceAll("!", "")
		.replaceAll("(", "")
		.replaceAll(")", "")

		.replaceAll("Ñ", "")
		.replaceAll("ñ", "")
		.replaceAll("¿", "")
		.replaceAll("¡", "")
		.replaceAll("Ü", "")
		.replaceAll("ü", "")
		.replaceAll("Ç", "")
		.replaceAll("ç", "")
		.replaceAll("ö", "")
		.replaceAll("ä", "")
		.replaceAll("å", "")
		.replaceAll("ì", "")
		
		.replaceAll("è", "")
		.replaceAll("ù", "")
		.replaceAll("é", "")
		.replaceAll("ò", "")
		.replaceAll("à", "")
		;
}
	